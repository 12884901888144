<template>
  <div id="chartContainer">
    <base-bar-chart
      :chart-data="dataCollection"
      :options="options"
    />
    <br>
    <br>
    <div v-if="dataExist">
      <v-row>
        <v-col
          cols="3"
          class="footer-text"
        >
          <div class="container-box-1">
            <small>Total Estimated Amount: </small> <br> <b>{{ calculatedAmounts.total_estimated_amount }} <font
              class="currency-style"
            >
              {{ chartData[0].cash_flow.project.currency }}
            </font></b>
          </div>
        </v-col>
        <v-col
          cols="3"
          class="footer-text"
        >
          <div class="container-box-3">
            <small>Total Credit (Client Amount):</small> <br> <b>{{ calculatedAmounts.total_client_amount }} <font
              class="currency-style"
            >
              {{ chartData[0].cash_flow.project.currency }}
            </font></b>
          </div>
        </v-col>
        <v-col
          cols="3"
          class="footer-text"
        >
          <div class="container-box-2">
            <small>Total Debit (Invoice Amount):</small> <br> <b>{{ calculatedAmounts.total_invoice }} <font
              class="currency-style"
            >
              {{ chartData[0].cash_flow.project.currency }}
            </font></b>
          </div>
        </v-col>
        <v-col
          cols="3"
          class="footer-text"
        >
          <div class="container-box-4">
            <small>Variance (credit - debit):</small> <br> <b>{{ calculatedAmounts.total_balance }} <font class="currency-style">
              {{ chartData[0].cash_flow.project.currency }}
            </font></b>
          </div>
        </v-col>
      </v-row>
    </div>
    <br>
    <br>
    <div class="table-content">
      <v-card
        color="#FFF"
        elevation="0"
      >
        <v-card-title class="table-header" />
        <v-data-table
          :headers="headers"
          :items="chartData"
          class="elevation-1 table-cash-flow"
          disable-sort
          hide-default-footer
          mobile-breakpoint="100"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{ item.job.name }}
              </td>
              <td>
                {{ item.estimated_amount || 0 }} <font class="currency-style">
                  {{ item.cash_flow.project.currency }}
                </font>
              </td>
              <td>
                {{ item.client_amount || 0 }} <font class="currency-style">
                  {{ item.cash_flow.project.currency }}
                </font>
              </td>
              <td>
                {{ item.invoice_amount || 0 }} <font class="currency-style">
                  {{ item.cash_flow.project.currency }}
                </font>
              </td>
              <td>
                <font
                  v-if="(item.client_amount - item.invoice_amount) < 0"
                  color="red"
                >
                  -
                </font>
                <Font>{{ Math.abs(item.client_amount - item.invoice_amount) || 0 }}</Font>
                <font class="currency-style">
                  {{ item.cash_flow.project.currency }}
                </font>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <centre-spinner :loading="loading" />
  </div>
</template>

<script>
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import moment from 'moment';
export default {
  name: 'CashFlowChart',
  components: {
    'centre-spinner': spinner,
  },
  data () {
    return {
      loading: false,
      headers: [
        { text: 'Milestone', align: 'start', value: 'job.name' },
        { text: 'Estimated Amount', align: 'start', value: 'job.name' },
        { text: 'Credit (Client Amount)', align: 'start', value: 'client_amount' },
        { text: 'Debit (Invoice Amount)', value: 'invoice_amount' },
        { text: 'Balance (Credit -Debit)', value: 'balance' },
      ],
      label: 'Active Check-Ins',
      currentDateTime: moment(),
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontColor: '#000',
            fontsize: 14,
          },
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: '#000',
                autoSkip: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                autoSkip: false,
              },
            },
          ],
        },
      },
    };
  },
  computed: {
    chartData () {
      return this.$store.getters['cashflow/getCashFlows'] || [];
    },
    dataExist () {
      return this.chartData.length > 0;
    },
    dataCollection () {
      if (this.dataExist === false) {
        return {
          labels: [],
          datasets: [],
        };
      }
      return this.generateDataCollection();
    },
    calculatedAmounts () {
      var totalInvoice = 0;
      var totalClientAmount = 0;
      var totalBalance = 0;
      var totalEstimatedAmount = 0;
      this.chartData.filter((item) => {
        totalInvoice = totalInvoice + Number(item.invoice_amount);
      });
      this.chartData.filter((item) => {
        totalEstimatedAmount = totalEstimatedAmount + Number(item.estimated_amount);
      });
      this.chartData.filter((item) => {
        totalClientAmount = totalClientAmount + Number(item.client_amount);
      });
      this.chartData.filter((item) => {
        totalBalance = totalBalance + Number(Math.abs(item.client_amount - item.invoice_amount));
      });
      return {
        total_invoice: totalInvoice,
        total_client_amount: totalClientAmount,
        total_balance: totalBalance,
        total_estimated_amount: totalEstimatedAmount,
      };
    },
  },
  async mounted () {
    this.loading = true;
    await this.$store.dispatch('cashflow/clearCashFlows');
    await this.getCashFlows();
    this.loading = false;
  },
  methods: {
    getCashFlows () {
      const projectId = this.$route.params.projectId;
      this.$store.dispatch('cashflow/fetchCashFlows', {
        projectId: projectId,
      });
    },
    generateDataCollection () {
      const clientAmount = [];
      const estAmount = [];
      const invoiceAmount = [];
      const labels = [];
      for (const result of this.chartData) {
        clientAmount.push(result.client_amount);
        estAmount.push(result.estimated_amount);
        invoiceAmount.push(result.invoice_amount);
        labels.push(result.job.name);
      }
      return {
        labels: labels,
        datasets: [
          {
            data: clientAmount,
            label: 'Client Payment',
            backgroundColor: 'rgb(109, 66, 199, 0.5)',
            borderColor: 'rgb(109, 66, 199)',
            borderWidth: 1,
            fill: true,
            barThickness: 40,
            borderRadius: 10,
          },
          {
            data: estAmount,
            label: 'Job Cost',
            backgroundColor: 'rgb(98, 111, 230, 0.5)',
            borderColor: 'rgb(98, 111, 230)',
            borderWidth: 1,
            fill: true,
            barThickness: 40,
            borderRadius: 10,
          },
          {
            data: invoiceAmount,
            label: 'Invoice Cost',
            backgroundColor: 'rgb(138, 172, 255, 0.5)',
            borderColor: 'rgb(138, 172, 255)',
            borderWidth: 1,
            fill: true,
            barThickness: 40,
            borderRadius: 10,
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.currency-style {
  color: #FF3700;
  font-size: 10px;
  font-weight: 700;
}

.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474f !important;
  font-weight: bold !important;
}

.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474f !important;
}

.v-data-table {
  background-color: #ffffff;
}

.table-cash-flow {
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}

.table-header {
  border: 1px solid #ccc !important;
  padding: 15px;
}

.footer-text {
  font-size: 16px;
}

.table-footer {
  border-bottom: 1px solid #CCC !important;
  border-left: 1px solid #CCC !important;
  border-right: 1px solid #CCC !important;
  padding: 15px;
}

.container-box-1 {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid red;
  background: rgba(255, 0, 0, 0.082);
}

.container-box-2 {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid blue;
  background: rgba(0, 0, 255, 0.082);
}

.container-box-3 {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid rgb(0, 204, 255);
  background: rgba(0, 174, 255, 0.082);
}

.container-box-4 {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid green;
  background: rgba(0, 128, 0, 0.082);
}
</style>
